import axios from 'axios';
import Providers from '@/providers';
import {checkAgainstUnauthorizedResponse} from "@/common/utils";

const requestGymUrl = process.env.VUE_APP_ROUTE_ENDPOINT_URL + '/v1/route/gym/request';

const requestGym = async (countryCode: string, postal: string, gymName: string): Promise<void> => {
  const config = {
    headers: {
      Authorization: `Bearer ${Providers.getAccessToken().value}`,
    },
  };
  try {
    await axios.post(
      requestGymUrl,
      {
        countryCode,
        postal,
        gymName,
      },
      config,
    );
  } catch (error:any ) {
    checkAgainstUnauthorizedResponse(error);
    console.error(error);
    throw error;
  }
};

export default requestGym;
