
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';

import GymRequestForm from '@/components/gym-selector/GymRequestForm.vue';

export default defineComponent({
  name: 'RequestGym',
  components: {
    IonContent,
    IonPage,
    GymRequestForm,
  },
});
